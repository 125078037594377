import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    fetchData();
    getUser();
}, [fetchData, getUser]);

  return (
<div className="container-fluid">
  <div className="row">
    <div className="col-md-8 leftContainer" style={{ minHeight: "100vh", backgroundColor: "#f5f5f5", padding: "70px" }}>
     <div className="support-info text-center" style={{ width: "100vv" }}>
    <div className="games-section-title" style={{ fontSize: "1.2em", fontWeight: "600", color: "#333", padding: "15px", borderRadius: "8px", backgroundColor: "#fff", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
        <b>Contact Us on Our Platforms Below</b>
        <p style={{ margin: "10px 0 5px", fontSize: "0.9em", color: "#555" }}>Monday to Saturday</p>
        <span style={{ color: "red", fontWeight: "bold" }}>Support 24/7</span>
    </div>
</div>

      <div className="cxy flex-column" style={{ paddingTop: "10%" }}>
        <img src="https://i.postimg.cc/3RvPdcH0/support-gif.gif" width="240px" alt="Support GIF" style={{ margin: "0 auto" }} />
      </div>

      <div className="contact-options mt-5">
        <div className="row">
          <div className="col-md-12 mb-3">
            <a href="https://t.me/haryana_ludoking" target="_blank" rel="noreferrer">
              <button className="btn btn-primary btn-block text-white" style={{ padding: "12px 20px", borderRadius: "8px", width: "100%", fontWeight: "bold" }}>
                <img width="25px" src="https://i.postimg.cc/02qsw8Zn/Telegram.png" alt="Telegram" />
                <span className="ml-2">Telegram</span>
              </button>
            </a>
          </div>

          <div className="col-md-12 mb-3">
            <a href="https://www.instagram.com/haryana_ludo.in" target="_blank" rel="noreferrer">
              <button className="btn btn-danger btn-block text-white" style={{ padding: "12px 20px", borderRadius: "8px", width: "100%", fontWeight: "bold" }}>
                <img width="25px" src="https://i.postimg.cc/Y95w8k7p/instagram.png" alt="Instagram" />
                <span className="ml-2">Instagram</span>
              </button>
            </a>
          </div>

          <div className="col-md-12 mb-3">
            <a href={`https://wa.me/7852885169?text=hello%20admin%20i%20need%20help%20%3F%20phone=${user?.Phone}`} target="_blank" rel="noreferrer">
              <button className="btn btn-success btn-block text-white" style={{ padding: "12px 20px", borderRadius: "8px", width: "100%", fontWeight: "bold" }}>
                <img width="25px" src="https://i.postimg.cc/VkfPKDxb/whatsapp.png" alt="WhatsApp" />
                <span className="ml-2">WhatsApp</span>
              </button>
            </a>
          </div>

          <div className="col-md-12 mb-3">
            <a href={`mailto:${WebSitesettings.CompanyEmail}`} target="_blank" rel="noreferrer">
              <button className="btn btn-warning btn-block text-white" style={{ padding: "12px 20px", borderRadius: "8px", width: "100%", fontWeight: "bold" }}>
                <img width="25px" src="https://i.postimg.cc/J7X80y3t/email.png" alt="Email" />
                <span className="ml-2">{WebSitesettings.CompanyEmail}</span>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="company-details mt-4 text-center">
        <p style={{ fontSize: "0.9em", fontWeight: "500", color: "#333" }}>
          {WebSitesettings ? WebSitesettings.CompanyAddress : ""}
        </p>
      </div>
    </div>

    <div className="col-md-4 rightContainer" style={{ backgroundColor: "#333", color: "#fff", padding: "20px" }}>
      <Rightcontainer />
    </div>
  </div>
</div>



  );
};
export default Support;
